import React from 'react';
import solarInstallation from '../images/solar-installation.svg';
import energyAudit from '../images/energy-audit.svg';
import solarMaintenance from '../images/solar-maintenance.svg';
import solarConsulting from '../images/solar-consulting.svg';
const Services = () => {
    return (
        <div id="services" className="bg-transparent py-4 px-5 overflow-hidden">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4 ">
                    <h2 className="text-center text-3xl text-white uppercase font-bold">Services</h2>
                    <div className='flex justify-center'>
                        <div className='mt-2 w-24 border-b-4 border-green-500'></div>
                    </div>


                </div>
                <div className="px-4 lg:px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                        {/* Solar Installation */}
                        <div className="outline-1 outline-gray-700 outline px-2 bg-transparent bg-opacity-50 transition-all ease-in-out duration-300 overflow-hidden text-gray-300 hover:bg-green-600 hover:text-white rounded-lg shadow-lg flex flex-col">
                            <div className="w-full h-24 flex items-center justify-center">
                                <img alt="Solar Installation" className="w-1/3 h-auto object-contain ml-44
                                 mt-14" src={solarInstallation} />
                            </div>
                            <div className="p-4 flex flex-col flex-grow">
                                <h3 className="font-semibold text-xl mb-2">Solar Installation</h3>
                                <p className="text-sm flex-grow mb-4">
                                    Custom solar panels for homes and businesses with optimal integration.
                                </p>
                            </div>
                        </div>

                        {/* Energy Audits */}
                        <div className="outline-1 outline-gray-700 outline px-2 bg-opacity-50 transition-all ease-in-out duration-300 overflow-hidden text-gray-300 hover:bg-green-600 hover:text-white rounded-lg shadow-lg flex flex-col">
                            <div className="w-full h-24 flex items-center justify-center">
                                <img alt="Energy Audits" className="w-2/3 h-auto object-contain ml-44
                                 mt-14" src={energyAudit} />
                            </div>
                            <div className="p-4 flex flex-col flex-grow">
                                <h3 className="font-semibold text-xl mb-2">Energy Audits</h3>
                                <p className="text-sm flex-grow mb-4">
                                    Assess your energy use and identify solar opportunities.
                                </p>
                            </div>
                        </div>

                        {/* Solar Maintenance */}
                        <div className="outline-1 outline-gray-700 outline px-2 bg-opacity-50 transition-all ease-in-out duration-300 overflow-hidden text-gray-300 hover:bg-green-600 hover:text-white rounded-lg shadow-lg flex flex-col">
                            <div className="w-full h-24 flex items-center justify-center">
                                <img alt="Solar Maintenance" className="w-2/3 ml-44
                                 mt-14  h-auto object-contain" src={solarMaintenance} />
                            </div>
                            <div className="p-4 flex flex-col flex-grow">
                                <h3 className="font-semibold text-xl mb-2">Solar Maintenance</h3>
                                <p className="text-sm flex-grow mb-4">
                                    Regular maintenance to ensure system efficiency and longevity.
                                </p>
                            </div>
                        </div>

                        {/* Solar Consulting */}
                        <div className="outline-1 outline-gray-700 outline md:px-2 bg-opacity-50 transition-all ease-in-out duration-300 overflow-hidden text-gray-300 hover:bg-green-600 hover:text-white rounded-lg shadow-lg flex flex-col">
                            <div className="w-full h-24 flex items-center justify-center">
                                <img alt="Solar Consulting" className="w-2/3 ml-40 mt-10 h-auto object-contain" src={solarConsulting} />
                            </div>
                            <div className="p-4 flex flex-col flex-grow">
                                <h3 className="font-semibold text-xl mb-2">Solar Consulting</h3>
                                <p className="text-sm flex-grow mb-4">
                                    Guidance on solar technology, regulations, and financing.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <h3 className="text-2xl md:text-3xl text-green-500 font-bold">We <span className='font-black'>Innovate</span></h3>
                            <p className='my-3 text-lg md:text-xl text-gray-300 font-semibold'>
                                With over 10 years of experience, we offer innovative solar solutions to reduce your carbon footprint and energy costs.
                            </p>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <h3 className="text-2xl md:text-3xl text-green-500 font-bold">We <span className='font-black'>Empower</span></h3>
                            <p className='my-3 text-lg md:text-xl text-gray-300 font-semibold'>
                                We equip clients with the knowledge and tools to effectively harness solar energy, tailored to your needs.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default Services;
