import React from 'react';
import { BackgroundBeams } from '../components/ui/backgroud_beams';
const cardsData = [
    {
        name: 'Naeem Ahmad',
        position: 'CEO',
        phone: '+92-333-786767',
        email: 'naeem.ceo@bmygreenpower.com',
        website: 'Bmygreenpower.com',
        addresses: [
            'Office #119, Shadman Town, Sargodha Road, Fsd.',
            '105-Office No. 45, Tower Faisal Town, Rawalpindi.',
            'Bohar Gate Near Saqafat Marriage Lawn, Bahawalpur.'
        ]
    },
    {
        name: 'Malik Muhammad Anjum',
        position: 'C.O.O',
        phone: '+92-321-2121622',
        email: 'anjum.coo@bmygreenpower.com',
        website: 'Bmygreenpower.com',
        addresses: [
            'Office #119, Shadman Town, Sargodha Road, Fsd.',
            '105-Office No. 45, Tower Faisal Town, Rawalpindi.',
            'Bohar Gate Near Saqafat Marriage Lawn, Bahawalpur.'
        ]
    },
    {
        name: 'Imran hassan khan',
        position: 'Director',
        phone: '+92-307-7833041',
        email: 'imran.director.tech@bmygreenpower.com',
        website: 'Bmygreenpower.com',
        addresses: [
            'Office #119, Shadman Town, Sargodha Road, Fsd.',
            '105-Office No. 45, Tower Faisal Town, Rawalpindi.',
            'Bohar Gate Near Saqafat Marriage Lawn, Bahawalpur.'
        ]
    },
    {
        name: 'Ahmad Mujtaba',
        position: 'HRM',
        phone: '+92-336-7920024',
        email: 'ahmad.mujtaba@bmygreenpower.com',
        website: 'Bmygreenpower.com',
        addresses: [
            'Office #119, Shadman Town, Sargodha Road, Fsd.',
            '105-Office No. 45, Tower Faisal Town, Rawalpindi.',
            'Bohar Gate Near Saqafat Marriage Lawn, Bahawalpur.'
        ]
    },
    {
        name: 'Nasir Ali Qurashi',
        position: 'Director Marketing',
        phone: '+92-300-8657182',
        email: 'nasir.director@bmygreenpower.com',
        website: 'Bmygreenpower.com',
        addresses: [
            'Office #119, Shadman Town, Sargodha Road, Fsd.',
            '105-Office No. 45, Tower Faisal Town, Rawalpindi.',
            'Bohar Gate Near Saqafat Marriage Lawn, Bahawalpur.'
        ]
    },
    {
        name: 'Adeena Naeem Ahmad',
        position: 'Director Legal Affairs',
        phone: '+92-313-7248428',
        email: 'adeena.directorlegal@bmygreenpower.com',
        website: 'Bmygreenpower.com',
        addresses: [
            'Office #119, Shadman Town, Sargodha Road, Fsd.',
            '105-Office No. 45, Tower Faisal Town, Rawalpindi.',
            'Bohar Gate Near Saqafat Marriage Lawn, Bahawalpur.'
        ]
    },
];

const Cards = () => {
    return (
        <>
            <BackgroundBeams />
            <div id="cards" className="bg-black py-4 px-5 overflow-hidden mt-16">
                <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="text-center text-3xl text-green-500 uppercase font-bold">Our Team</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-green-500'></div>
                        </div>
                        <h2 className="mt-4 mx-6 text-center text-xl lg:text-2xl font-semibold text-green-500">
                            Dedicated to Green Energy Solutions
                        </h2>
                    </div>

                    <div className="px-4 lg:px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
                            {cardsData.map((card, index) => (
                                <div key={index} className="bg-transparent border-2 border-green-500 bg-opacity-60 transition-all ease-in-out duration-300 overflow-hidden text-white hover:bg-green-600 hover:text-white rounded-lg shadow-lg flex flex-col">
                                    <div className="p-4 flex flex-col flex-grow">
                                        <h3 className="font-semibold text-xl mb-2">{card.name}</h3>
                                        <p className="text-sm font-semibold">{card.position}</p>
                                        <a href={`https://wa.me/${card.phone.replace(/[^0-9]/g, '')}`} target="_blank" rel="noopener noreferrer" className="text-sm underline">{card.phone}</a>
                                        <a href={`mailto:${card.email}`} className="text-sm underline">{card.email}</a>
                                        <p className="text-sm">{card.website}</p>
                                        <div className="mt-4">
                                            {card.addresses.map((address, i) => (
                                                <p key={i} className="text-sm">{address}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Cards;
