import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = ({ handleClick }) => {
    return (
        <>
            <HashLink onClick={handleClick} className="px-4 font-extrabold text-green-500 hover:text-green-300" smooth to="/#about">
                About
            </HashLink>
            <HashLink onClick={handleClick} className="px-4 font-extrabold text-green-500 hover:text-green-300" smooth to="/#services">
                Services
            </HashLink>
            <HashLink onClick={handleClick} className="px-4 font-extrabold text-green-500 hover:text-green-300" to="/project#project">
                Projects
            </HashLink>
            <HashLink onClick={handleClick} className="px-4 font-extrabold text-green-500 hover:text-green-300" to="/contact">
                Contact
            </HashLink>
            <a onClick={handleClick}
                className="text-white bg-green-600 hover:bg-green-500 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                href="mailto:contactus@bmygreenpower.com?subject=Consultation Request&body=Hello,%0D%0A%0D%0AI would like to request a consultation.%0D%0A%0D%0AThank you."
            >
                Consultation
            </a>
        </>
    );
}

export default NavLinks;
