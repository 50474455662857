// import React, { useEffect, useState } from 'react';
// import { motion } from 'framer-motion';
import { ParallaxScroll } from "../components/ui/images";
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../images/project', false, /\.(png|jpe?g|svg|JPG|jpg|PNG)$/));

const ImageGallery = () => {
    // const [isVisible, setIsVisible] = useState(Array(images.length).fill(false));
    // const [selectedImage, setSelectedImage] = useState(null);

    // useEffect(() => {
    //     const timeouts = images.map((_, index) =>
    //         setTimeout(() => {
    //             setIsVisible((prev) => {
    //                 const updated = [...prev];
    //                 updated[index] = true;
    //                 return updated;
    //             });
    //         }, index * 300) // Delay for staggered appearance
    //     );

    //     return () => {
    //         timeouts.forEach((timeout) => clearTimeout(timeout));
    //     };
    // }, []);

    // const openImage = (image) => {
    //     setSelectedImage(image);
    // };

    // const closeImage = () => {
    //     setSelectedImage(null);
    // };

    return (
        <>
            <div className="h-14 w-auto overflow-clip" id="project"></div>
            <div className="w-auto py-16 px-8 my-14 bg-black text-gray-300 overflow-hidden">
                <h2 className="text-4xl font-bold text-green-500 mb-8 text-center">Projects</h2>
                <div className="flex flex-wrap justify-center gap-4 overflow-hidden">
                    <ParallaxScroll images={images} />
                </div>
            </div>

        </>
    );
};

export default ImageGallery;