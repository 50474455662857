import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
const Footer = () => {
    return (
        <footer className="bg-transparent text-gray-300 py-10 px-4 sm:px-6 border-t-[1px] border-green-600">
            <div className="max-w-7xl mx-auto">

                {/* Top area: Blocks */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 py-8 md:py-12">

                    {/* 1st block */}
                    <div className="bg-green-900 bg-opacity-40 p-6 rounded-lg shadow-lg">
                        <h3 className="font-bold text-3xl mb-4 text-white">BMY</h3>
                        <div className="text-md font-medium text-gray-300">
                            <h5>BMY Green Power Solution Pvt Ltd<sup>TM</sup></h5>
                            <hr></hr>

                            <div className="text-xs mt-2">
                                <div className="flex flex-grow flex-wrap gap-2 mb-4">
                                    <svg className="w-4 h-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><path d="M20 3H4C1.8 3 0 4.8 0 7v10c0 2.2 1.8 4 4 4h16c2.2 0 4-1.8 4-4V7c0-2.2-1.8-4-4-4zm1.6 5.8-7.9 5.3c-.5.3-1.1.5-1.7.5s-1.2-.2-1.7-.5L2.4 8.8c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5 1.4-.2l7.9 5.3c.3.2.8.2 1.1 0l7.9-5.3c.5-.3 1.1-.2 1.4.3.2.4.1 1-.3 1.3z" fill="#008040" class="fill-000000"></path></svg>
                                    <p>contactus@bmygreenpower.com</p>
                                </div>
                                <p>Office #119, Shadman Town, Sargodha Road, Fsd.</p>
                                <p>105-Office No. 45, Tower Faisal Town, Rawalpindi.</p>
                                <p>Bohar Gate Near Saqafat Marriage Lawn, Bahawalpur.</p>
                            </div>
                        </div>
                    </div>

                    {/* 2nd block */}
                    <div className="bg-green-900 bg-opacity-40 p-6 rounded-lg shadow-lg">
                        <h6 className="text-green-300 text-xl font-bold mb-4">LINKS</h6>
                        <ul className="text-md">
                            <li className="mb-2">
                                <HashLink to="/#about" className="text-green-300 hover:text-white transition duration-250 ease-in-out">About</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="/#services" className="text-green-300 hover:text-white transition duration-250 ease-in-out">Services</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="/Contact" className="text-green-300 hover:text-white transition duration-250 ease-in-out">Contact</HashLink>
                            </li>
                            <li className="mb-2">
                                <Link to="/project" className="text-green-300 hover:text-white transition duration-250 ease-in-out">Projects</Link>
                            </li>
                        </ul>
                    </div>

                    {/* 3rd block */}
                    <div className="bg-green-900 bg-opacity-40 p-6 rounded-lg shadow-lg">
                        <h6 className="text-green-300 text-xl font-bold mb-4">OUR SERVICES</h6>
                        <ul className="text-md">
                            <li className="mb-2">
                                <HashLink to="/#portfolio" className="text-green-300 hover:text-white transition duration-250 ease-in-out">Industrial Specialist</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="/#portfolio" className="text-green-300 hover:text-white transition duration-250 ease-in-out">Commercial Specialist</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="/#portfolio" className="text-green-300 hover:text-white transition duration-250 ease-in-out">Residential Solar Installation</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="/#portfolio" className="text-green-300 hover:text-white transition duration-250 ease-in-out">Agriculture Specialist</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="/#portfolio" className="text-green-300 hover:text-white transition duration-250 ease-in-out">Solar System Maintenance</HashLink>
                            </li>
                        </ul>
                    </div>

                    {/* 4th block */}
                    <div className="text-center bg-green-900 bg-opacity-40 p-6  pt-16 rounded-lg shadow-lg">
                        <div className="text-xl mb-4 text-green-300 font-bold">
                            Social Media Links
                        </div>
                        <div className="text-md font-medium mb-4">
                            Follow us on social media
                        </div>
                        <div className="flex justify-center space-x-4">
                            <a href="https://www.instagram.com/bmygreenpowersolution/" target="_blank" rel="noopener noreferrer" className="text-green-300 hover:text-white transition duration-150 ease-in-out mt-1" aria-label="Instagram">
                                <svg className="w-6 h-6 fill-current" viewBox="0 0 169.063 169.063" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752c17.455,0,31.656,14.201,31.656,31.655V122.407z" fill="currentColor" />
                                        <path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z" fill="currentColor" />
                                        <path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78C135.661,29.421,132.821,28.251,129.921,28.251z" fill="currentColor" />
                                    </g>
                                </svg>
                            </a>
                            <Link to="#" className="text-green-300 hover:text-white transition duration-150 ease-in-out" aria-label="Facebook">
                                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                </svg>
                            </Link>
                        </div>
                    </div>

                </div>

                {/* Footer bottom */}
                <div className="border-t border-gray-800 py-4">
                    <div className="text-center text-sm text-gray-500">
                        Copyright &copy; {new Date().getFullYear()}{" "}
                        <HashLink
                            to="#"
                            className="text-green-300 hover:text-white"
                        >
                            BMY Green Power Solution Pvt Ltd <sup>TM</sup>
                        </HashLink>. All rights reserved.
                    </div>
                </div>

            </div>
        </footer>
    );
}

export default Footer;
