import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
// import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import { HeroHighlight } from "../components/ui/highlight";


const Home = () => {
    return (
        <>

            <Hero />
            <HeroHighlight>
                <Intro />
                <Services />
                <Portfolio />
                <Clients />
                <Cta />
            </HeroHighlight>
        </>

    )
}

export default Home;

