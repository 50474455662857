import React from 'react';
import img from '../images/solar-panel.svg';
import { Highlight } from "../components/ui/highlight";

const Intro = () => {
    return (
        <>
            {/* <div className="bg-[url(./images/hero-bg.svg)] bg-no-repeat bg-top bg-cover transform rotate-180 w-screen h-screen absolute">
            </div> */}
            <div className="h-14 w-[95vw]" id="about"></div>
            <div className="container mx-auto p-2 md:p-12" id="about">
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between items-center lg:text-left" data-aos="fade-up">
                    <div className=" lg:w-1/3 sm:w-1/2 flex flex-col items-center lg:items-start lg:mx-4 justify-center">
                        <img alt="solar panel img" className="rounded-t w-[70vw] lg:w-3/4" src={img} />
                    </div>
                    <div className="flex-col my-4 lg:my-0 w-full lg:w-1/2 px-8 lg:px-0" data-aos="zoom-in" data-aos-delay="500">
                        <h3 className="text-3xl text-green-300 font-bold mb-4">
                            Empowering Your Future with Sustainable<br /><Highlight>Solar Solutions</Highlight>
                        </h3>
                        <div>
                            <p className="my-3 text-xl text-gray-300 font-semibold">
                                At BMY, we specialize in providing high-quality solar installation and consulting services to help you harness the power of the sun.
                            </p>
                        </div>
                        <div>
                            <p className="my-3 text-xl text-gray-300 font-semibold">
                                Our team is dedicated to delivering custom solar solutions that meet your unique energy needs, improve efficiency, and contribute to a greener planet.
                            </p>
                        </div>
                        <a href="/contact" className="text-white bg-green-600 hover:bg-green-500 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Contact us
                            <svg className="w-4 h-4 ml-1 group-hover:translate-x-2 transition-transform duration-300 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
