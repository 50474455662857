import React from 'react';

const Portfolio = () => {
    const email = "contactus@bmygreenpower.com";

    return (
        <>
            <div className="my-4 py-4 overflow-x-clip" id='portfolio'>
                <h2 className="my-2 text-center text-2xl md:text-3xl text-white uppercase font-bold">Solar Solutions</h2>
                <div className='flex justify-center'>
                    <div className='w-16 border-b-4 border-green-500 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 p-4">
                        {/* Industrial Specialist */}
                        <div id="industrial-consultation" className="bg-black text-white p-4 rounded-lg shadow-lg flex flex-col border-2 border-green-700">
                            <h4 className="font-semibold text-lg mb-2">Industrial Specialist</h4>
                            <p className="text-sm flex-grow mb-4">
                                High-efficiency solar systems tailored for large-scale operations. Maximize energy output and reduce costs.
                            </p>
                            <a href={`mailto:${email}?subject=Industrial%20Consultation&body=I%20am%20interested%20in%20Industrial%20Solar%20Consultation.`} className="text-white bg-black hover:bg-green-500 border-2 border-green-500 inline-flex items-center justify-center px-4 py-2 text-sm rounded-md shadow-md hover:text-black">
                                Consultation
                                <svg className="w-4 h-4 ml-1 group-last-hover:translate-x-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>

                        {/* Commercial Specialist */}
                        <div id="business-consultation" className="bg-black text-white p-4 rounded-lg shadow-lg flex flex-col border-2 border-green-700">
                            <h4 className="font-semibold text-lg mb-2">Commercial Specialist</h4>
                            <p className="text-sm flex-grow mb-4">
                                Scalable solar systems for businesses to cut costs and carbon footprint. Customized for various commercial properties.
                            </p>
                            <a href={`mailto:${email}?subject=Commercial%20Consultation&body=I%20am%20interested%20in%20Commercial%20Solar%20Consultation.`} className="text-white bg-black hover:bg-green-500 border-2 border-green-500 inline-flex items-center justify-center px-4 py-2 text-sm rounded-md shadow-md hover:text-black">
                                Consultation
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>

                        {/* Agriculture Solar Specialist */}
                        <div id="agriculture-consultation" className="bg-black text-white p-4 rounded-lg shadow-lg flex flex-col border-2 border-green-700">
                            <h4 className="font-semibold text-lg mb-2">Agriculture Specialist</h4>
                            <p className="text-sm flex-grow mb-4">
                                Solar solutions for agricultural needs, including irrigation and lighting. Durable systems designed for farm conditions.
                            </p>
                            <a href={`mailto:${email}?subject=Agriculture%20Consultation&body=I%20am%20interested%20in%20Agriculture%20Solar%20Consultation.`} className="text-white bg-black hover:bg-green-500 border-2 border-green-500 inline-flex items-center justify-center px-4 py-2 text-sm rounded-md shadow-md hover:text-black">
                                Consultation
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>

                        {/* Residential Solar Systems */}
                        <div id="residential-systems" className="bg-black text-white p-4 rounded-lg shadow-lg flex flex-col border-2 border-green-700">
                            <h4 className="font-semibold text-lg mb-2">Residential Systems</h4>
                            <p className="text-sm flex-grow mb-4">
                                Tailored solar systems for homeowners to boost efficiency and savings. Includes panels, inverters, and battery options.
                            </p>
                            <a href={`mailto:${email}?subject=Residential%20Quote&body=I%20am%20interested%20in%20a%20quote%20for%20Residential%20Solar%20Systems.`} className="text-white bg-black hover:bg-green-500 border-2 border-green-500 inline-flex items-center justify-center px-4 py-2 text-sm rounded-md shadow-md hover:text-black">
                                Get a Quote
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>

                        {/* Solar Maintenance Services */}
                        <div id="maintenance-plans" className="bg-black text-white p-4 rounded-lg shadow-lg flex flex-col border-2 border-green-700 md:col-span-2 lg:col-span-1">
                            <h4 className="font-semibold text-lg mb-2">Maintenance Services</h4>
                            <p className="text-sm flex-grow mb-4">
                                Ensure peak efficiency with our maintenance packages. Includes inspections, cleaning, and performance monitoring.
                            </p>
                            <a href={`mailto:${email}?subject=Maintenance%20Plans&body=I%20am%20interested%20in%20your%20Maintenance%20Plans%20for%20Solar%20Systems.`} className="text-white bg-black hover:bg-green-500 border-2 border-green-500 inline-flex items-center justify-center px-4 py-2 text-sm rounded-md shadow-md hover:text-black">
                                View Plans
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Portfolio;
