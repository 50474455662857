import React from 'react';
import { Boxes } from "../components/ui/ctabg";

const Cta = () => {
    const mailToLink = "mailto:contactus@bmygreenpower.com?subject=Solar Consultation Request&body=Hello,%0D%0A%0D%0AI would like to request a solar consultation.%0D%0A%0D%0AThank you.";

    return (
        <>

            <div className="w-auto flex items-center justify-center text-black cta bg-transparent overflow-x-clip z-40 pt-5 relative overflow-hidden border-t-[1px] border-x-[1px] mt-10 mx-9 mb-1 rounded-t-3xl border-green-950">
                <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">
                    <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                        <div className="mb-4 z-40">
                            <p className='text-2xl md:text-4xl font-bold mb-4 text-green-500 '>Ready to harness the power of the sun?</p>
                            <p className="text-lg md:text-2xl text-gray-300">Let's illuminate your future with <span className='font-black'>solar energy!</span></p>
                        </div>

                        <div className="w-full lg:w-72 pt-6 lg:mx-12 z-50">
                            <a href={mailToLink} className="bg-transparent border border-green-500 hover:bg-green-600 hover:text-black text-gray-300 justify-center text-center rounded-lg px-10 py-3 flex items-center group transition duration-600 ease-in-out">
                                Get Solar Consultation
                                <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                        <div className="hidden md:block">
                            <Boxes />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Cta;
