import React from 'react';
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';

// Define an array of image paths (local or URLs)
const imagePaths = [
    require('../images/clients/1 (1).JPG'),
    require('../images/clients/greps (1).jpg'),
    require('../images/clients/1 (2).JPG'),
    // Add more images as needed
];

const Clients = () => {


    return (
        <div className="mt-8 bg-transparent mx-10  overflow-x-clip pb-10">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">Our Clients</h2>
                    <div className="flex justify-center">
                        <div className="w-24 border-b-4 border-green-700"></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-green-500">
                        Trusted by leading organizations for solar solutions.
                    </h2>
                </div>

                <div className="p-4" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-8">
                        {imagePaths.map((path, index) => (
                            <motion.div
                                key={index}
                                className="overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 bg-transparent p-4 rounded-lg"
                                whileHover={{ scale: 1.1 }} // Framer Motion animation
                            >
                                <div className="w-48 h-32 flex items-center justify-center">
                                    <img src={path} alt={`Client ${index}`} className="object-contain w-full h-full" />
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>
            <div className="flex justify-center mt-8">
                <div
                    className="px-6 py-2 bg-black text-white rounded-lg hover:bg-green-500  hover:text-black transition-all border-2 border-green-700"
                >
                    <HashLink to="/project#project">
                        Open Projects
                    </HashLink>
                </div>
            </div>
        </div >
    );
}

export default Clients;