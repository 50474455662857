import React, { useState, useEffect } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/logo.png';
import { motion } from 'framer-motion'; // Add this import

const NavBar = () => {
    const [top, setTop] = useState(!window.scrollY);
    const [isOpen, setisOpen] = useState(false);

    function handleClick() {
        setisOpen(!isOpen);
    }

    function closeMenu() {
        setisOpen(false);
    }

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    return (
        <nav className={`fixed top-4 left-1/2 transform -translate-x-1/2 w-[93vw] z-30 transition duration-300 ease-in-out mb-16 bg-[#0D0E11] shadow-lg
         rounded-2xl border-t-[1px] border-t-green-800 border-x-[1px] border-b-[1px] border-b-green-900  border-x-green-900 bg-opacity-80 backdrop-filter backdrop-blur-sm
        `}>
            <div className="flex flex-row justify-between items-center px-4">
                <div className="flex flex-row justify-center md:px-1 md:mx-1 items-center text-center font-semibold hover:scale-105 transition-transform duration-150">
                    <img src={logo} alt="BMY Green Power Solution Pvt Ltd" className="rounded-md my-3 mr-1" width={40} height={40} />
                    <HashLink smooth to="/#hero">
                        <motion.h4
                            className="font-extrabold text-sm text-green-600 lg:text-xl"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.1 }}
                            whileHover={{
                                opacity: 1,
                                transition: { staggerChildren: 0.1 }
                            }}
                        >
                            BMY
                            {Array.from(" Green Power Solution Pvt Ltd").map((letter, index) => (
                                <motion.span key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: index * 0.05 }}>
                                    {letter}
                                </motion.span>
                            ))}

                        </motion.h4>
                    </HashLink>
                </div>

                <div className="group flex flex-col  items-center">
                    <button className="p-2 rounded-lg lg:hidden text-green-600" onClick={handleClick}>
                        <svg className={`h-6 w-6 fill-current transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {isOpen ? (
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            ) : (
                                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2z" />
                            )}
                        </svg>
                    </button>
                    <div className='hidden lg:inline-block p-5 bg-transparent'>
                        <NavLinks handleClick={closeMenu} />
                    </div>
                </div>
            </div>

            <motion.div
                className={`flex justify-start lg:hidden${isOpen ? "p-3 m-5" : ""}`}
                initial={{ height: 0 }}
                animate={{ height: isOpen ? "auto" : 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: 'hidden' }}
            >
                <div className='flex flex-col space-y-6'>
                    <NavLinks handleClick={closeMenu} />
                </div>
            </motion.div>
        </nav>
    );
}

export default NavBar;